import { modalInit  , modalInitImg } from "./main"

const sm = 768
const setFilter = document.querySelectorAll('[data-set="filter"]')
const locale = document.querySelector('html').getAttribute('lang')
let normalURL 

const scrollTo = (element) => {
    document.getElementById(element).scrollIntoView({
        behavior: 'smooth',
        block: 'start'
    })
}

const forms = document.querySelectorAll('[data-form]');
let showPages = [];


function updateForms (forms){
    forms.forEach(form => {
        form.addEventListener('submit', e => {
            e.preventDefault();
        })
    
        const formName = form.dataset.form;
        const replaceCode = document.querySelector(`#${formName}-replaceCode`);
        const replacePagination = document.querySelector(`#${formName}-replacePagination`);
        const formInputs = document.querySelectorAll(`form[data-form="${formName}"] input[type="checkbox"], form[data-form="${formName}"] input[type="radio"]`);
    
        formInputs.forEach(input => {
            input.addEventListener('change', () => {
                if (input.hasAttribute('data-all')) {
                    const dataAll = input.dataset.all;
                    const allInputs = document.querySelectorAll(`form[data-form="${formName}"] input[type="checkbox"][name="${dataAll}"], form[data-form="${formName}"] input[type="radio"][name="${dataAll}"]`);
                    allInputs.forEach(elem => {
                        if (elem.dataset.all) return;
                        elem.checked = false;
                    })
                    // console.log(window.innerWidth);
    
                    forSubmitForm()
    
                } else {
                    const allInput = document.querySelector(`form[data-form="${formName}"] input[type="checkbox"][data-all="${input.name}"], form[data-form="${formName}"] input[type="radio"][data-all="${input.name}"]`);
                    if (allInput) {
                        allInput.checked = false;
                    }
                    forSubmitForm()
                }
            })
        })
    
        const paginate = () => {
            const paginationItems = document.querySelectorAll(`#${formName}-replacePagination .pagination-item [data-page]`);
            const showMore = document.querySelector(`#${formName}-replacePagination .pagination-button[data-show-page]`);
    
            paginationItems.forEach(item => {
                item.addEventListener('click', e => {
                    e.preventDefault();
                    let page = item.dataset.page;
                    submitForm(page);
                    showPages = [];
                })
            })
    
            if (showMore) {
                showMore.addEventListener('click', e => {
                    e.preventDefault();
                    let page = showMore.dataset.showPage;
                    submitForm(page, true);
                })
            }
    
        }
        paginate();
    
        const submitForm = (page = 1, showmore = false) => {
            const formData = new FormData(form);
            const url = form.action;
            const method = form.method;
    
            if (url.indexOf('api/') > -1) {
                normalURL = url.split('api/')[1];
                // console.log(normalURL)
            } else {
                normalURL = url;
            }
    
            let urlSerarator = '?'
            if (url.indexOf('?') > -1) {
                urlSerarator = '&'
            }
    
            if (page > 1) {
                formData.append('page', page);
                showPages.push(page);
            }
            // correctly get params creation
            const data = [...formData.entries()];
            let query = data
                .map(x => `${x[0]}=${encodeURIComponent(x[1])}`)
                .join('&');
            // console.log(query);
            // let query = new URLSearchParams(formData).toString();
            if (formData.get('tags[]') == 'all') {
                query = '';
            }
            window.history.pushState({},"",`?${query}`);
            // console.log(`${normalURL}?${query}`)
    
            fetch( url + urlSerarator + query, {
                method: method
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                replacePagination.innerHTML = data.paginate;
                
                if (showmore) {
                    replaceCode.insertAdjacentHTML('beforeend', data.code);
                    // console.log(showPages)
                    addActivePages(showPages);
                } else {
                    replaceCode.innerHTML = data.code;
                    scrollTo(`${formName}-scrollToReplaceCode`);
                }
    
                paginate();
                modalInit();
                modalInitImg();
            });
        }
    
        // check for mobile version
        const forSubmitForm = () => {
            if (window.innerWidth > sm) {
                submitForm();
            } else {
                // console.log(setFilter)
                setFilter.forEach(el => {
                    el.addEventListener('click', () => {
                        submitForm();
                        const activeForm = document.querySelectorAll('.filtersActive')
                        if (activeForm) {
                            activeForm.forEach(btn => {
                                btn.classList.remove('filtersActive')
                                document.querySelector('body').classList.remove('noScroll')
                            })
                        }
                    })
                })
            }
        }
    
        const addActivePages = (pages) => {
            const firstPage = Number(pages[0]) -1;
            let pagesList = []
            if (firstPage >= 1) {
                pagesList.push(firstPage);
                pagesList.push(...pages);
            } else {
                pagesList.push(...pages);
            }
            // console.log("pagesList: ", pagesList)
            pagesList.forEach(page => {
                const paginationItem = document.querySelector(`.pagination-item [data-page="${Number(page)}"]`);
                if (paginationItem) paginationItem.parentNode.classList.add('pagination-active');
            })
        }
    })
}

updateForms(forms)



// for reviews

let serviceUrl
let typeUrl
let doctorUrl


let reviewsBTN = document.querySelectorAll('.js-reviews');
let setMobileFilterForFeviews = document.getElementById('setMobileFilterForFeviews');
let reviewsBTNActive =  document.querySelectorAll('.js-reviews.active');


const updateReviewsBtns = (reviewsBTN) => {
    reviewsBTN?.forEach(btn => {
        btn.addEventListener('click', event => {
            if (window.innerWidth < sm) {
                event.preventDefault()
                const mobileReviewsFilter = event.target.parentElement
                const mobileReviewsFilterGroup = mobileReviewsFilter.dataset?.name
                const mobileReviewsFilterGroupElement = document.querySelectorAll(`[data-name="${mobileReviewsFilterGroup}"]`);
    
                mobileReviewsFilterGroupElement.forEach(groupBtn => {
                    groupBtn.classList.remove('active');
                })
    
                mobileReviewsFilter.classList.add('active');
    
                reviewsBTNActive = document.querySelectorAll('.js-reviews.active');
                
                updateFormForReviews(btn , false);
            }else{
                const link =  btn?.getAttribute('data-href')
                if(link) window.location.href = link
    
            }
        })
    })
}

updateReviewsBtns(reviewsBTN)




const initializeActiveFilters = () => {
    reviewsBTNActive?.forEach(btn => {
        updateFormForReviews(btn , true)
    })
}

 const updateFormForReviews = async (btn , init) => {
    const form = document.querySelector('[data-form="reviews"]')
    const formContent = form.querySelector('.reviews__filters-content')
    // console.log(form)
    const btnType = btn?.getAttribute('data-name');
    const btnValue = btn?.getAttribute('data-value');
    if(btnType == 'service') serviceUrl = btnValue;
    if(btnType == 'type') typeUrl = btnValue;
    if(btnType == 'doctor') doctorUrl = btnValue;
    if(!init) {
        const data = await getData({doctor: doctorUrl, service: serviceUrl, type: typeUrl})
        formContent.innerHTML = data;
        reviewsBTN = document.querySelectorAll('.js-reviews');
        setMobileFilterForFeviews = document.getElementById('setMobileFilterForFeviews');
        reviewsBTNActive =  document.querySelectorAll('.js-reviews.active');
        updateReviewsBtns(reviewsBTN)

    } 

}

setMobileFilterForFeviews?.addEventListener('click', event => {

    let friendlyURL;
    let servicesParams;
    let typeParam;
    let doctorsParam;

    reviewsBTNActive?.forEach(btn => {
        // console.log(btn, btn.hasAttribute('data-friendly-url'))
        // is friendly URL?
        if (btn.hasAttribute('data-friendly-url')) {
            if (btn?.dataset.friendlyUrl == '1') {
                friendlyURL = true;
            } else {
                friendlyURL = false;
            }
        }

        if (btn.hasAttribute('data-name') && btn.dataset.name == 'service') {
            servicesParams = btn.dataset.value
        }
        if (btn.hasAttribute('data-name') && btn.dataset.name == 'doctor') {
            doctorsParam = btn.dataset.value
        }
        if (btn.hasAttribute('data-name') && btn.dataset.name == 'type') {
            typeParam = btn.dataset.value
        }
    })

    const homeLink = window.location.origin;
    const reviewLink = `${homeLink}/${locale}/vidhuky`;

    let serviceLink = reviewLink;

    // Додаємо параметр service, якщо він є
    if (servicesParams) {
        serviceLink = `${reviewLink}?service=${servicesParams}`;
    }

    // Якщо параметр doctor заданий, додаємо його з урахуванням існування параметра service
    let doctorLink = serviceLink;
    if (doctorsParam) {
        doctorLink += serviceLink.includes('?') 
            ? `&doctor=${doctorsParam}` 
            : `?doctor=${doctorsParam}`;
    }

    // Додаємо параметр type, якщо він відрізняється від 'text'
    let typeLink = doctorLink;
    if (typeParam && typeParam !== 'text') {
        typeLink += doctorLink.includes('?') 
            ? `&type=${typeParam}` 
            : `?type=${typeParam}`;
    }

    // Встановлюємо фінальний URL
    const redirectLink = typeLink;

    window.location.href = redirectLink;

})

initializeActiveFilters();



const getData = async ({doctor , service , type }) => {
    const params = new URLSearchParams();
    let action 
    // Додаємо параметри тільки якщо вони є
    if (doctor) params.append('doctor', doctor);
    if (service) params.append('service', service);
    if (type) params.append('type', type);
    action = `/${locale}/api/reviews/sidebar?${params.toString()}`;
    // action = `/${locale}/api/reviews/sidebar?
    if(!action) return
    try {
      const response = await fetch(action, {
        method: 'GET',
        headers: {
          'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();

      console.log(data.data)
      return data.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Проброс помилки для обробки у виклику функції
    }
};


