import { headerHover } from "./headerHover";


let init = document.querySelector('.header__top-numbers-init svg')
if(init) {
    init.addEventListener('click', () => {
        document.querySelector('.header__top-numbers-init a').click();
    })
}
let binotelTrigger = document.querySelectorAll('[data-binotel-trigger]');
if(binotelTrigger) {
    binotelTrigger.forEach(item => {
        item.addEventListener('click', function (event) {
            event.preventDefault();
            event.stopPropagation();
            let data = event.target.getAttribute('data-binotel-trigger');
            document.querySelector(`[data-binotel-observer="${data}"]`)?.click();
        })
    })
}



function scrollDetect() {
    var lastScroll = 0;
    const header_on_scroll = document.querySelector('header');

    if (header_on_scroll) {
        window.onscroll = function () {
            let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

            if (currentScroll > lastScroll && currentScroll >= 100) {
                // Scrolling down by 50px or more
                header_on_scroll.classList.add('scrolled-down');
                lastScroll = currentScroll;
            } else if (lastScroll > currentScroll && currentScroll < lastScroll - 50) {
                // Scrolling up by 50px or more
                header_on_scroll.classList.remove('scrolled-down');
                lastScroll = currentScroll;
            }
        };
    }
}
scrollDetect();


function menu(){
    const menu = document.querySelector('.header__bottom')
    const menuTrigger = document.querySelector(".header__burger");
    const overlay = document.querySelector(".menu-overlay")
    const body = document.querySelector("body")
    const firstList = document.querySelector('.menu-list.first-list')
    const menuClose = firstList.children[0].querySelector('.menu-close')
    const firstItems = firstList.children[1].children


    const activeItems = firstList.querySelectorAll('.menu-item .active-menu-link')
    const activeItem = activeItems[0]
    function checkingActiveLink(){
        if(activeItem) {
            const secondLevel = activeItem.closest('.menu-list .second-list')
            const thirdLevel = activeItem.closest('.menu-list .third-list')
            if(secondLevel){
                secondLevel.click()
                firstList.style.height = secondLevel.children[0].clientHeight + secondLevel.children[1].clientHeight + 'px'
                secondLevel.classList.add('isOpen')
                if( thirdLevel ){
                    const thirdLevelHeight = thirdLevel.children[0].clientHeight + thirdLevel.children[1].clientHeight
                    secondLevel.style.height = thirdLevelHeight + 'px'
                    firstList.style.height = thirdLevelHeight + 'px'
                    thirdLevel.classList.add('isOpen')
                    thirdLevel.click()
                }
            }
        }
    }
    menuTrigger.addEventListener("click", function (){
        checkingActiveLink()
        menu.classList.add('active-menu')
        overlay.classList.add('active-overlay-menu')
        body.classList.add("noScroll")
    })

    function checkActiveList(){
        const allLists = document.querySelectorAll('.menu-list')
        allLists.forEach((list) => {
            list.style.height = 'auto'
            if(list.classList.contains('isOpen')){
                list.classList.remove('isOpen')
            }
        })
    }

    overlay.addEventListener("click", function (){
        checkActiveList()
        menu.classList.remove('active-menu')
        overlay.classList.remove('active-overlay-menu')
        body.classList.remove("noScroll")
    })


    menuClose.addEventListener("click", function (){
        checkActiveList()
        menu.classList.remove('active-menu')
        overlay.classList.remove('active-overlay-menu')
        body.classList.remove("noScroll")
    })

    Array.from(firstItems).forEach((firstItem) => {
        firstItem.addEventListener("click", function (event) {
            const secondList = firstItem.querySelector('.menu-list.second-list')
            if(secondList){
                event.stopPropagation()
                const secondListHeight = secondList.children[0].clientHeight + secondList.children[1].clientHeight
                firstList.style.height = secondListHeight +'px'
                secondList.classList.add("isOpen");
                if(secondList.classList.contains("isOpen")){
                    event.stopPropagation()
                    // const closeSecondList = secondList.children[0].querySelector('.menu-close')
                    const closeSecondList = secondList.children[0]
                    const closeEvent = closeSecondList.addEventListener("click", function (event) {
                        event.stopPropagation();
                        secondList.classList.remove("isOpen");
                        closeSecondList.removeEventListener('click',closeEvent)
                        firstList.style.height = 'auto';
                    });
                }
                const secondItems = secondList.children[1].children
                Array.from(secondItems).forEach((secondItem) => {
                    secondItem.addEventListener("click", function (event) {
                        const thirdList = secondItem.querySelector('.menu-list.third-list')
                        event.stopPropagation();
                        if(thirdList){
                            const thirdListHeight = thirdList.children[0].clientHeight + thirdList.children[1].clientHeight
                            firstList.style.height = thirdListHeight +'px'
                            thirdList.classList.add("isOpen");
                            secondList.style.height = thirdListHeight +'px';
                            event.stopPropagation();
                            if(thirdList.classList.contains("isOpen")){
                                event.stopPropagation()
                                // const closeThirdList = thirdList.children[0].querySelector('.menu-close')
                                const closeThirdList = thirdList.children[0]
                                closeThirdList.addEventListener("click", function (event) {
                                    thirdList.classList.remove("isOpen");
                                    firstList.style.height = secondListHeight +'px';
                                    secondList.style.height = 'auto';
                                    event.stopPropagation();
                                });
                            }
                        }
                    })
                })
            }
        });
    })
}

var screenWidth = window.innerWidth;

if(screenWidth < 991){
    menu()
}else{
    headerHover()
}
