export function headerHover() {
  const body = document.querySelector("body")
  const firstLevel = document.querySelector('.menu-list.first-list')
  const firstItems = firstLevel.querySelectorAll(':scope > .menu-ul > .menu-item');


  firstItems.forEach((item) => {
    // Додаємо слухач для події hover (mouseenter/mouseleave)
    item.addEventListener('mouseenter', () => {
      const secondLevel = item.querySelector('.menu-list.second-list')
      if(secondLevel) {
        body.classList.add("noScroll")
        const secondItems = secondLevel.querySelectorAll(':scope > .menu-ul > .menu-item');
        secondItems?.forEach((secondItem) => {
          secondItem?.addEventListener('mouseenter', () => {
            const thirdLevel = secondItem.querySelector('.menu-list.third-list');
            if (thirdLevel) {
              const rect = secondItem.getBoundingClientRect();

              thirdLevel.style.position = 'fixed';
              thirdLevel.style.top = `${rect.top}px`;
              thirdLevel.style.left = `${rect.right}px`; 
              thirdLevel.style.transition = '0s';
            }
          });

          secondItem.addEventListener('mouseleave', () => {
            const thirdLevel = secondItem.querySelector('.menu-list.third-list');
            if (thirdLevel) {
              thirdLevel.style.position = ''; 
              thirdLevel.style.top = '';
              thirdLevel.style.left = '';
            }
          });
        });
      }
    });

    item.addEventListener('mouseleave', () => {
      body?.classList?.remove("noScroll")
    });
  });
}