import Swiper, {Autoplay, Navigation, Pagination , Virtual} from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const modal = document.querySelector('.gallery-modal')
const body = document.querySelector('body')
const close = document.querySelector('.gallery-modal__close')


const gallerySwiper = new Swiper('.gallery-modal__swiper', {
  modules: [Navigation, Pagination , Virtual],
  slidesPerView: 1,
  spaceBetween: 10,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    dynamicBullets: true,
    clickable: true
  },
  virtual: {
    enabled: true,
  },
});

function initModal (){
  modal?.classList.add('active-modal')
  body.classList.add('noScroll')

}

if(modal && close){

  
  const closeModal = () => {
    modal.classList.remove('active-modal');
    body.classList.remove('noScroll');
    gallerySwiper.removeAllSlides()
  }
  
  close.addEventListener('click', () => {
    closeModal()
  })
  
  modal.addEventListener('click', (event) => {
    const self = event.target.closest('.gallery-modal__body');
    if (!self || event.target.classList.contains('gallery-modal__close')) {
      closeModal()
    }
  })

  
  
  
  

}

export async function gallerySwiperInit(urlInit) {
  // Remove the first `checkedValues = []` because we declare it below
  const inputs = document.querySelectorAll('[name="clinics[]"]');
  
  // Declare `checkedValues` only once with `const`
  const checkedValues = Array.from(inputs)
    .filter(input => input.checked)
    .map(input => input.value);

  // console.log(checkedValues);
  
  const url = checkedValues && checkedValues[0]
    ? `/api/photogallery/all?clinic[]=${checkedValues[0]}` 
    : `/api/photogallery/all`;
  
  const res = await fetchData(url);

  if (!res || res.length === 0) {
    console.warn('No data received or empty array');
    return;
  }

  // console.log('Fetched data:', res);

  // Генеруємо HTML для слайдів
  const slides = res.map((el) => {
    const img = document.createElement('img');
    img.src = el.path;
    return img.outerHTML; // Повертаємо HTML слайда
  });

  // Додаємо слайди
  gallerySwiper.virtual.appendSlide(slides);
  gallerySwiper.update(); // Оновлюємо Swiper

  // Знайти індекс слайда з потрібним зображенням
  const targetIndex = res.findIndex((el) => el.path === urlInit);
  if (targetIndex !== -1) {
    // console.log(`Scrolling to slide index: ${targetIndex}`);
    gallerySwiper.slideTo(targetIndex); // Прокрутити до слайда
  } else {
    console.warn('Image URL not found in slides');
  }

  initModal(); // Ініціалізація модального вікна
}

async function fetchData(url) {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}




